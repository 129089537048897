import { SORT_VALUE } from '@/constants/common.const';

export const clientSideSort = <T>(array: T[], key: string, sortValue: number, locale?: string) => {
  if (sortValue === SORT_VALUE.NONE) {
    return array;
  }

  const getCategory = (char: string) => {
    if (/[!@#]/.test(char)) {
      return 1;
    }
    if (/[0-9]/.test(char)) {
      return 2;
    }
    if (/[A-Z]/.test(char)) {
      return 3;
    }
    if (/[a-z]/.test(char)) {
      return 4;
    }
    return 5;
  };

  return array.slice().sort((a: T, b: T) => {
    const aValue = a[key as keyof T];
    const bValue = b[key as keyof T];

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      const categoryA = getCategory(aValue[0]);
      const categoryB = getCategory(bValue[0]);

      if (categoryA !== categoryB) {
        return sortValue === SORT_VALUE.ASC ? categoryA - categoryB : categoryB - categoryA;
      } else {
        return sortValue === SORT_VALUE.ASC
          ? aValue.localeCompare(bValue, locale)
          : bValue.localeCompare(aValue, locale);
      }
    }

    if (aValue < bValue) {
      return sortValue === SORT_VALUE.DESC ? SORT_VALUE.DESC : SORT_VALUE.ASC;
    }
    if (aValue > bValue) {
      return sortValue === SORT_VALUE.DESC ? SORT_VALUE.ASC : SORT_VALUE.DESC;
    }
    return SORT_VALUE.NONE;
  });
};
